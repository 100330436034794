import React from "react"
import { graphql } from "gatsby"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Post from "../components/post"

const ToursPostTemplate = ({
  data: { config, translations, tour, latest },
}) => {
  return (
    <Layout>
      <Post
        data={tour}
        other={latest}
        otherHeading={translations.global.latestTours}
        otherCta={translations.global.seeAll}
        otherCtaUrl={`/${config.pages.tours.slug}`}
      />
    </Layout>
  )
}

export default ToursPostTemplate

export const Head = ({ data: { tour } }) => {
  return (
    <Meta
      data={{
        title: tour.title,
        socialImage: tour.images[0]?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        latestTours
        seeAll
      }
    }

    config: configJson {
      pages {
        tours {
          slug
        }
      }
    }

    tour: toursJson(id: { eq: $id }) {
      title
      body
      links {
        id
        url
        text
      }
      files {
        id
        url
        text
      }
      images {
        ...NKimageFragment
      }
      sites {
        ...SitesCardFragment
      }
    }

    latest: allToursJson(
      filter: { id: { ne: $id } }
      sort: { publish_at: DESC }
      limit: 3
    ) {
      nodes {
        ...ToursCardFragment
      }
    }
  }
`
