import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
// import Pagination from "../components/pagination"
import PostsHighlights from "../components/posts-highlights"
import PostCard from "../components/post-card"
import { ReactComponent as SvgBulb } from "../assets/images/icons/bulb.svg"

const Grid = styled.ul`
  ${({ theme }) => theme.grid.container()}
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX()}
  ${({ theme }) => theme.grid.gridGutterY(30)}

  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    margin-bottom: ${rem(20)};
  }

  > li {
    ${({ theme }) => theme.grid.cell(4)}

    @media ${({ theme }) => theme.mq.xlargeDown} {
      ${({ theme }) => theme.grid.cell(6)}
    }

    @media ${({ theme }) => theme.mq.smallDown} {
      ${({ theme }) => theme.grid.cell(12)}
    }
  }
`

const ToursPage = ({ data: { config, tours } }) => {
  const heroItemsMax = 4
  let heroItems = null
  let gridItems = null

  if (tours && tours.nodes.length >= heroItemsMax) {
    heroItems = tours.nodes.slice(0, heroItemsMax)
    gridItems = tours.nodes.slice(heroItemsMax)
  } else {
    heroItems = tours.nodes
  }

  return (
    <Layout>
      {heroItems && !!heroItems.length && (
        <PostsHighlights
          data={heroItems}
          heading={config.pages.tours.title}
          headingLevel={1}
          icon={<SvgBulb />}
          hideDate={true}
        />
      )}

      {gridItems && !!gridItems.length && (
        <React.Fragment>
          <Grid>
            {gridItems.map((item, i) => (
              <li key={i}>
                <PostCard data={item} />
              </li>
            ))}
          </Grid>

          {/* <Pagination pageCount={4} onPageChange={() => null} /> */}
        </React.Fragment>
      )}
    </Layout>
  )
}

export default ToursPage

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.tours.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      pages {
        tours {
          title
        }
      }
    }

    tours: allToursJson(sort: { publish_at: DESC }) {
      nodes {
        ...ToursCardFragment
      }
    }
  }
`
