import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { hideVisually, rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import MediaBlock from "../components/media-block"
import TypeCtas from "../components/type-ctas"
import IndexSites from "../components/index-sites"
import PostsHighlights from "../components/posts-highlights"
import { ReactComponent as SvgBulb } from "../assets/images/icons/bulb.svg"
import heroVideo from "../assets/videos/hero-video.mp4"

const MapWrap = styled.section`
  height: 60vh;
  min-height: ${rem(220)};
  margin-top: ${rem(60)};
  position: relative;

  @media ${({ theme }) => theme.mq.largeDown} {
    margin-top: ${rem(40)};
  }

  > h2 {
    ${hideVisually()}
  }

  > .gatsby-image-wrapper {
    width: ${rem(200)};
    height: ${rem(200)};
    position: absolute !important;
    z-index: 1;
    top: ${rem(-30)};
    right: ${rem(100)};
    pointer-events: none;
    filter: drop-shadow(0 ${rem(10)} ${rem(15)} rgba(0, 0, 0, 0.7));

    @media ${({ theme }) => theme.mq.largeDown} {
      width: ${rem(140)};
      height: ${rem(140)};
      right: ${rem(80)};
      filter: drop-shadow(0 ${rem(5)} ${rem(10)} rgba(0, 0, 0, 0.5));
    }

    @media ${({ theme }) => theme.mq.mediumDown} {
      display: none;
    }
  }
`

const Hr = styled.div`
  ${({ theme }) => theme.grid.container()}

  div {
    height: 0;
    margin-bottom: ${rem(40)};
    display: block;
    overflow: hidden;
    border-top: 1px solid ${({ theme }) => theme.colors.cream};
  }
`

const IndexPage = ({
  data: {
    config,
    translations,
    latestArticles,
    latestTours,
    stages,
    stays,
    seeDo,
    eatDrink,
    heroFirstFrame,
    imgMapPreview,
  },
}) => {
  const sites = []

  const findType = (name) => config.types.find((t) => t.name === name)

  if (stages.nodes.length) {
    sites.push({ type: findType(`stages`), items: stages.nodes })
  }

  if (stays.nodes.length) {
    sites.push({ type: findType(`stays`), items: stays.nodes })
  }

  if (seeDo.nodes.length) {
    sites.push({ type: findType(`seeDo`), items: seeDo.nodes })
  }

  if (eatDrink.nodes.length) {
    sites.push({ type: findType(`eatDrink`), items: eatDrink.nodes })
  }

  return (
    <Layout>
      <MediaBlock
        headingLevel={1}
        data={{
          video: heroVideo,
          image: heroFirstFrame,
          title: config.pages.index.hero.title,
          cta: {
            title: config.pages.index.hero.cta,
            url: config.pages.index.hero.ctaUrl,
          },
        }}
      />

      <TypeCtas />

      <MapWrap>
        <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

        <GatsbyImage
          image={imgMapPreview.childImageSharp.gatsbyImageData}
          objectFit="contain"
          alt={translations.global.map}
        />

        <Map
          query={`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}&category=31`} // eslint-disable-line prettier/prettier
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
          zoom={7.2}
        />
      </MapWrap>

      <PostsHighlights
        data={latestTours.nodes}
        heading={translations.global.latestTours}
        // moreText={translations.global.seeAll}
        // moreLink={`/${config.pages.tours.slug}`}
        icon={<SvgBulb />}
        hideDate={true}
      />

      {sites.map(({ type, items }, i) => (
        <React.Fragment key={i}>
          {i > 0 && (
            <Hr>
              <div />
            </Hr>
          )}

          <IndexSites type={type} sites={items} />
        </React.Fragment>
      ))}

      <PostsHighlights
        data={latestArticles.nodes}
        heading={translations.global.latestArticles}
        moreText={translations.global.seeAll}
        moreLink={`/${config.pages.articles.slug}`}
        icon={<SvgBulb />}
        masonry={true}
      />
    </Layout>
  )
}

export default IndexPage

export const Head = ({ data: { config } }) => {
  return (
    <Meta
      data={{
        title: config.pages.index.title,
        titleOverridePattern: true,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        map
        seeAll
        latestArticles
        latestTours
      }
    }

    config: configJson {
      pages {
        index {
          title
          hero {
            title
            cta
            ctaUrl
          }
        }
        articles {
          slug
        }
        tours {
          slug
        }
      }

      types {
        name
        slug
        color
        title
        tagline
        description
      }
    }

    stages: allSitesJson(
      filter: { the_type: { eq: "stages" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    stays: allSitesJson(
      filter: { the_type: { eq: "stays" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    seeDo: allSitesJson(
      filter: { the_type: { eq: "seeDo" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    eatDrink: allSitesJson(
      filter: { the_type: { eq: "eatDrink" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    latestArticles: allArticlesJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    latestTours: allToursJson(sort: { publish_at: DESC }, limit: 4) {
      nodes {
        ...ToursCardFragment
      }
    }

    imgCycling: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/180929-0029.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }

    imgMapPreview: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/map-preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 480, placeholder: NONE)
      }
    }

    heroImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }

    heroFirstFrame: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/hero-first-frame.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`
