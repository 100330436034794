import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { ParagraphLarge } from "../styled/paragraph"
import logoUrl from "../../assets/images/logo-white.svg"

const Container = styled.section``

const Logos = styled.h2`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media ${({ theme }) => theme.mq.xlargeDown} {
    justify-content: center;
  }

  a {
    margin-right: 1.25em;
    margin-bottom: 1.25em;
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transition: none;
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
    max-width: 16em;
    display: block;
  }
`

const Description = styled(ParagraphLarge)`
  color: inherit;
`

const About = ({ ...props }) => {
  const { config } = useStaticQuery(graphql`
    query {
      config: configJson {
        footer {
          about
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <Logos>
        <Link to="/" title="Vanerleden">
          <img src={logoUrl} alt="Vanerleden" />
        </Link>
      </Logos>

      <Description dangerouslySetInnerHTML={{ __html: config.footer.about }} />
    </Container>
  )
}

export default About
