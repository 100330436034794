import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import TypeIconSvg from "../type-icon-svg"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"

const Container = styled.nav``

const Sub = styled.div`
  min-width: ${rem(240)};
  max-width: ${rem(1240)};
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  flex-wrap: wrap;
  display: none;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(8)};

  @media ${({ theme }) => theme.mq.largeUp} {
    border-top-left-radius: 0;
  }

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    min-width: auto;
    position: static;
    box-shadow: 0 ${rem(2)} ${rem(6)} rgba(0, 0, 0, 0.2);
  }
`

const Base = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }

  > li {
    position: relative;
    color: ${({ theme }) => theme.colors.black};

    &:not(:last-child) {
      margin-right: 1em;

      @media ${({ theme }) => theme.mq.xxxlargeDown} {
        margin-right: 0.5em;
      }
    }

    &[data-active="true"],
    &:hover,
    &:focus-within {
      z-index: 5000;

      /* overlay */
      &[data-has-sub="true"]::before {
        content: "";
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.5);
        animation: ${({ theme }) => theme.animations.fadeIn} 0.5s;

        @media ${({ theme }) => theme.mq.largeDown} {
          display: none;
        }
      }

      > a,
      > button {
        background-color: ${({ theme }) => theme.colors.white};

        &:hover {
          color: ${({ theme }) => theme.colors.startBlue};
        }

        &:active {
          color: ${({ theme }) => theme.colors.startBlue};
        }

        /* inverted corners */
        &::before {
          content: "";
          width: 1em;
          height: 1em;
          position: absolute;
          bottom: 0;
          left: 100%;
          transform: scaleX(-1);
          background-color: ${({ theme }) => theme.colors.white};
          mask-image: radial-gradient(
            circle 1em at 0 0,
            transparent 0,
            transparent 1em,
            black calc(1em + 1px)
          );

          @media ${({ theme }) => theme.mq.largeDown} {
            display: none;
          }
        }
      }

      ${Sub} {
        display: flex;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}

      padding: 1.4em 1.2em;
      display: block;
      font-size: ${rem(18)};
      white-space: nowrap;
      border-top-left-radius: 0.4em;
      border-top-right-radius: 0.4em;

      @media ${({ theme }) => theme.mq.largeDown} {
        padding: 0.5em 0;
      }

      svg {
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.4em;
        display: inline-block;
      }
    }
  }
`

const SubItemsContainer = styled.div`
  width: 100%;
  padding: ${rem(30)};
  animation: ${({ theme }) => theme.animations.slideInY(-10)} 0.5s
    ${({ theme }) => theme.easings.default};

  @media ${({ theme }) => theme.mq.largeDown} {
    padding: ${rem(15)};
    font-size: 0.75em;
  }

  > a {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.625em;
    }

    &:hover {
      > .-icon {
        transform: scale(1.15);
      }

      > span {
        color: ${({ theme }) => theme.colors.startBlue};
      }
    }

    &:active {
      opacity: 0.6;
    }

    > .-icon {
      margin-right: 1em;
      flex-shrink: 0;
      transition: transform 0.3s ${({ theme }) => theme.easings.default};
    }

    > span {
      font-size: 1.25em;
      color: ${({ theme }) => theme.colors.metallic};
    }
  }
`

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

const SubItems = ({ data }) => {
  return (
    <SubItemsContainer>
      {data
        .filter((item) => !item.url.includes(`even`))
        .map((item, i) => (
          <Link key={i} to={item.url}>
            {item.name && (
              <HexagonIcon
                iconSvg={<TypeIconSvg name={item.name} />}
                className="-icon"
                aria-hidden="true"
                title={item.title}
                bgColor={item.color}
              />
            )}

            <span dangerouslySetInnerHTML={{ __html: item.title }} />
          </Link>
        ))}
    </SubItemsContainer>
  )
}

const PrimaryNav = () => {
  const {
    config: {
      types,
      header: { primaryNav },
    },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          name
          slug
          color
          title
        }

        header {
          primaryNav: primary_nav {
            types
            items {
              title
              url
              sub {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const items = [
    {
      title: primaryNav.types,
      sub: types.map((t) => ({ url: `/${t.slug}`, ...t })),
    },
    ...primaryNav.items,
  ]

  return (
    <Container>
      <Base>
        {items.map((base, i) => (
          <li key={i} data-has-sub={!!base.sub}>
            <ButtonLink to={base.url}>
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.sub && <ChevronDownSvg />}
            </ButtonLink>

            {base.sub && (
              <Sub>
                <SubItems data={base.sub} />
              </Sub>
            )}
          </li>
        ))}
      </Base>
    </Container>
  )
}

export default PrimaryNav
