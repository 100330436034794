export default [
  {
    code: `en`,
    title: `English`,
    url: `https://en.vanerleden.se/`,
  },
  {
    code: `sv`,
    title: `Svenska`,
    url: `https://www.vanerleden.se/`,
  },
]
