import { rgba, rem } from "polished"

const infoblocks = {
  backgroundColor: ({ theme }) => rgba(theme.colors.cream, 0.2),
  openBackgroundColor: ({ theme }) => theme.colors.white,
  borderColor: ({ theme }) => rgba(theme.colors.gold, 0.2),
  borderRadius: rem(8),
}

export default infoblocks
