import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"

const Image = styled.figure`
  width: 5.625em;
  height: 3.75em;
  flex-shrink: 0;
  margin-right: 1.125em;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: ${rem(5)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Info = styled.div`
  margin-top: -0.1em;
`

const Date = styled.time`
  margin-top: 0.4em;
  display: block;
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.metallic};
`

const Title = styled(Heading4)`
  line-height: 1.2;
`

const Container = styled.article`
  width: 100%;

  a {
    display: flex;

    &:hover {
      ${Image} {
        opacity: 0.8;
      }

      ${Title} {
        color: ${({ theme }) => theme.colors.gold};
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }
`

const EventCardTiny = ({
  data: { title, image, date, path },
  titleTag = `h2`,
  ...props
}) => {
  return (
    <Container {...props}>
      <Link to={path}>
        <Image>{image && <GatsbyImage image={image} alt={title} />}</Image>

        <Info>
          {title && (
            <Title as={titleTag} dangerouslySetInnerHTML={{ __html: title }} />
          )}

          {date && (
            <Date datetime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}
        </Info>
      </Link>
    </Container>
  )
}

export default EventCardTiny
