import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { shuffle } from "lodash"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import SitesCarousel from "../sites-carousel"
import TypeIconSvg from "../type-icon-svg"
import { Label3 } from "../styled/label"
import { Heading2 } from "../styled/heading"
import { Paragraph } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Header = styled.header`
  margin-bottom: ${rem(30)};
  padding-right: ${rem(50)};
  position: relative;

  i,
  h2 {
    margin-bottom: ${rem(8)};
  }

  svg {
    width: ${rem(32)};
    height: ${rem(32)};
    position: absolute;
    top: 0;
    right: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.black};
  }
`

const Cta = styled.footer`
  margin-top: ${rem(30)};

  a {
    ${anchorStyles}

    &:hover {
      svg {
        transform: translateX(0.3em);
        color: ${({ theme }) => theme.colors.startBlue};
      }

      span {
        color: ${({ theme }) => theme.colors.startBlue};
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      vertical-align: middle;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  width: 100%;
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};

  ${({ $color }) =>
    $color &&
    css`
      ${Header} i {
        color: ${({ theme }) => theme.colors.black};
      }

      ${Cta} a {
        color: ${({ theme }) => theme.colors.black};
      }

      ${Header} svg {
        color: ${({ theme }) => theme.colors[$color]};
      }
    `}

  ${Heading2} {
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.fonts.set(`secondary`, `bold`)};
  }
`

const IndexSites = ({ type, sites }) => {
  const anySites = sites && !!sites.length

  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          seeAll
        }
      }
    }
  `)

  const [shuffledSites, setShuffledSites] = useState(sites || [])

  useEffect(() => {
    if (anySites && type.name != `stages`) {
      setShuffledSites(shuffle(sites))
    }
  }, [])

  if (!anySites) return null

  return (
    <Container $color={type.color}>
      <Header>
        <Label3 dangerouslySetInnerHTML={{ __html: type.title }} />

        {type.tagline && (
          <Heading2 dangerouslySetInnerHTML={{ __html: type.tagline }} />
        )}

        {type.description && (
          <Paragraph dangerouslySetInnerHTML={{ __html: type.description }} />
        )}

        <TypeIconSvg name={type.name} aria-hidden="true" />
      </Header>

      <SitesCarousel
        data={shuffledSites}
        type={type.name}
        visibleSlides={type.name == `stages` ? 3 : 4}
        hideTypeIcons={true}
      />

      <Cta>
        <Link to={`/${type.slug}`}>
          <span
            dangerouslySetInnerHTML={{ __html: translations.global.seeAll }}
          />

          <SvgArrowRight aria-hidden="true" />
        </Link>
      </Cta>
    </Container>
  )
}

export default IndexSites
