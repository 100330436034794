let colors = {
  white: `#fff`,
  black: `#212529`,
  metallic: `#43484c`,
  cream: `#efece8`,
  creamLight: `#f4f2ef`,
  cyan: `#03ace5`,
  liberty: `#5362ac`,
  gold: `#896c4c`,
  goldMetallic: `#827700`,
  brown: `#bb8b6f`,
  purple: `#735967`,
  pinkLavender: `#9f6095`,
  blue: `#416d88`,
  startBlue: `#006699`,
  blueMunsell: `#008099`,
  olive: `#797a62`,
  green: `#17823b`,
  red: `#884141`,
  naturkartanMapBg: `#f3f3ef`,
}

colors = {
  ...colors,
  // by function:
  imageBackground: colors.goldMetallic,
}

export default colors
