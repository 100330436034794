import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem, darken } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import TypeIconSvg from "../type-icon-svg"
import { heading3Styles } from "../styled/heading"

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: ${rem(7)} ${rem(-7)};
  }

  li {
    min-width: 33.333%;
    flex: 1;
    display: flex;
    padding: ${rem(7)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      min-width: 50%;
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      min-width: 100%;
    }
  }
`

const LinkStyled = styled(Link)`
  ${heading3Styles}

  width: 100%;
  height: 100%;
  padding: 1.4em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.cream};
  background-color: ${({ theme, $color }) =>
    theme.colors[$color || `metallic`]};
  border-radius: ${rem(5)};
  transition: background-color 0.3s ${({ theme }) => theme.easings.default};

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-width: 50%;
    padding: 1em 0.5em;
  }

  &:hover {
    background-color: ${({ theme, $color }) =>
      darken(0.1, theme.colors[$color || `metallic`])};

    > .-icon {
      transform: scale(1.15);
    }
  }

  &:active {
    transition: none;
    background-color: ${({ theme, $color }) =>
      darken(0.2, theme.colors[$color || `metallic`])};
  }

  > .-icon {
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};
  }
`

const TypeCtas = () => {
  const {
    config: { types, pages },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          name
          slug
          color
          title
        }

        pages {
          map {
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <Container>
      <ul>
        {types.map(({ name, slug, color, title }, i) => (
          <li key={i}>
            <LinkStyled to={`/${slug}`} $color={color}>
              <HexagonIcon
                iconSvg={<TypeIconSvg name={name} />}
                iconColor={color}
                className="-icon"
                aria-hidden="true"
              />

              {title}
            </LinkStyled>
          </li>
        ))}
        {/* They want the map in the ctas, but it's not a type */}
        <li key={types.length}>
          <LinkStyled to={`/${pages.map.slug}`} $color={`green`}>
            <HexagonIcon
              iconSvg={<TypeIconSvg name={`karta`} />}
              iconColor={`green`}
              className="-icon"
              aria-hidden="true"
            />
            {pages.map.title}
          </LinkStyled>
        </li>
      </ul>
    </Container>
  )
}

export default TypeCtas
